import React from "react";

const FilledTrashIcon = () => {
  return (
    <svg width="75" height="65" viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6" clipPath="url(#clip0)">
        <path
          d="M53.1626 11.0093C52.259 11.0093 51.6566 10.406 51.6566 9.50116V3.7703C51.506 3.46868 50.6024 3.01624 48.9458 3.01624H26.0542C24.5482 3.01624 23.4939 3.46868 23.3433 3.7703V9.50116C23.3433 10.406 22.7409 11.0093 21.8373 11.0093C20.9337 11.0093 20.3313 10.406 20.3313 9.50116V3.61949C20.3313 1.50812 22.7409 0 26.0542 0H48.9458C52.259 0 54.6687 1.50812 54.6687 3.61949V9.50116C54.6687 10.2552 54.0662 11.0093 53.1626 11.0093Z"
          fill="black"
        />
        <path
          d="M8.43384 9.04871L14.4579 61.3805C15.0603 63.6427 17.0182 65.1508 19.2772 65.1508H55.8736C58.1326 65.1508 60.0905 63.6427 60.6929 61.3805L66.717 9.04871H8.43384ZM25.4519 53.3874H25.3013C24.5483 53.3874 23.9459 52.7842 23.7953 52.0301L19.8796 22.1694C19.729 21.4153 20.3314 20.6612 21.235 20.5104C22.1387 20.3596 22.8917 20.9629 22.8917 21.8677L26.8073 51.7285C26.8073 52.4826 26.3555 53.2366 25.4519 53.3874ZM39.0061 52.0301C39.0061 52.935 38.4037 53.5382 37.5001 53.5382C36.5965 53.5382 35.9941 52.935 35.9941 52.0301V22.0185C35.9941 21.1137 36.5965 20.5104 37.5001 20.5104C38.4037 20.5104 39.0061 21.1137 39.0061 22.0185V52.0301ZM51.2049 52.1809C51.0543 52.935 50.4519 53.5382 49.6989 53.5382H49.5483C48.7953 53.3874 48.1929 52.6334 48.1929 51.8793L52.1085 22.0185C52.2591 21.2645 53.0122 20.6612 53.7652 20.6612C54.5182 20.812 55.1206 21.5661 55.1206 22.3202L51.2049 52.1809Z"
          fill="black"
        />
        <path
          d="M73.494 11.3109H1.50602C0.60241 11.3109 0 10.7077 0 9.8028C0 8.89793 0.60241 8.29468 1.50602 8.29468H73.494C74.3976 8.29468 75 8.89793 75 9.8028C75 10.7077 74.3976 11.3109 73.494 11.3109Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="75" height="65" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilledTrashIcon;
