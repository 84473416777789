/* eslint-disable react-hooks/rules-of-hooks */
import { useMachine } from "@xstate/react";
import React, { useContext, useEffect, useRef } from "react";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaPen } from "@react-icons/all-files/fa/FaPen";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import styled from "@emotion/styled";
import DDIconWhite from "../../icons/DDIconWhite";
import { LAPTOP_SM } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import { scrollToTop } from "../../utils/scroll";
import { isBrowser } from "../../utils/ssr";
import { getUserInitials } from "../../utils/string";
import { LoadingIcon } from "../Icon/Icon";
import { useNavigate } from "../Link/Link";
import Loading from "../Loading/Loading";
import { LoginContext } from "../LoginProvider/LoginProvider";
import MealPlanCard from "../MealPlanCard/MealPlanCard";
import { pageContext } from "../PageProvider/PageProvider";
import { SEOReactHelmet } from "../SEO/SEOReactHelmet";
import { Container, Content, ErrorMessage, HeaderContainer } from "./Container";
import IconTextButton from "./IconTextButton";
import { machine } from "./mealPlannerPageMachine";
import FilledTrashIcon from "./icons/FilledTrashIcon";
import Toolbar from "./Toolbar";
import { useTranslations } from "./translations";
import { MealPlanForList } from "./types";

const MealPlannerPage = () => {
  if (!isBrowser()) {
    return null;
  }
  const { locale } = useContext(pageContext);
  const { loading: isUserLoading, premium: isPremium, user } = useContext(LoginContext);

  const navigate = useNavigate();
  const tt = useTranslations();
  const [state, send] = useMachine(machine({ locale, navigate }));
  const mealPlans = state.context.mealPlans;
  const mealPlanPreferences = state.context.mealPlanPreferences;
  const activeMealPlan = state.context.activeMealPlan;
  const queryPage = state.context.queryPage;
  const userInitials = (user && getUserInitials(user)) as string;
  const mealPlansRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isUserLoading) {
      send({
        type: "USER_STATE_CHANGE",
        premium: isPremium,
        userNameInitials: userInitials,
        userId: user?.userId ? user?.userId : "",
      });
    }
  }, [isPremium, isUserLoading, send, user?.userId, userInitials]);

  const handleActivateMealplan = (id: string) => send({ type: "ACTIVATE_MEALPLAN", id });
  const handleDeactivateMealplan = () => send({ type: "DEACTIVATE_MEALPLAN" });
  const handleDeleteMealplan = (id: string) => send({ type: "DELETE_MEALPLAN", id });
  const handleGenerateMealplan = () => send({ type: "GENERATE_MEALPLAN" });
  const handleCreateMealplan = () => send({ type: "CREATE_MEALPLAN" });
  const handleToggleToolbar = (toggle: boolean) => send({ type: "TOGGLE_TOOLBAR", toggle: toggle });
  const handleNavigateToGenderStep = () => send({ type: "NAVIGATE_TO_GENDER_STEP" });
  const handleNavigateToAllMealplansPage = () => send({ type: "NAVIGATE_TO_ALL_MEAL_PLANS_PAGE" });
  const handleNavigateToSettingsPage = () => send({ type: "NAVIGATE_TO_SETTINGS_PAGE" });
  const handleUndoDelete = () => send({ type: "UNDO_DELETE" });
  const handleNextQueryPage = (page: number) => () => {
    mealPlansRef.current?.scrollIntoView?.({ behavior: "auto", block: "start" });
    send({ type: "UPDATE_QUERY_PAGE", queryPage: page });
  };

  if (state.matches("mealPlans.error") || state.matches("error")) {
    return (
      <Container>
        <Content>
          <ErrorMessage>{tt.errorMessageCannotGetMealPlansComponent}</ErrorMessage>
        </Content>
      </Container>
    );
  }
  if (state.matches("mealPlans.generateMealPlanError")) {
    return (
      <Container>
        <Content>
          <ErrorMessage>{tt.errors.default.title}</ErrorMessage>
          <p className="text-center w-auto">{tt.errorMessageCannotCreatePersonalizedMealPlan}</p>
          <div className="text-center">
            <IconTextButton
              variant="contained"
              color="green"
              onClick={handleNavigateToGenderStep}
              icon={<FaChevronRight />}
            >
              {tt.buttonGoToSurvey}
            </IconTextButton>
          </div>
        </Content>
      </Container>
    );
  }

  if (state.matches("loading") || state.matches("init")) {
    return <Loading />;
  }

  return (
    <>
      <SEOReactHelmet title={tt.pageTitle} />
      <Container>
        <Content>
          {state.matches("mealPlans.idle.showDeleteDialog") && (
            <DeleteDialog>
              <FilledTrashIcon />
              <p>{tt.deleteDialogTitle}</p>
              <button onClick={handleUndoDelete}>{tt.deleteDialogButtonText}</button>
            </DeleteDialog>
          )}
          <HeaderContainer ref={mealPlansRef}>
            <h1 data-testid="mealplanner-page-heading">{tt.mainHeading}</h1>
            <Toolbar
              mealPlanPreferences={mealPlanPreferences}
              loading={state.matches("mealPlans.generate")}
              generateNext={handleGenerateMealplan}
              createMealPlan={handleCreateMealplan}
              viewThemedMealPlans={handleNavigateToAllMealplansPage}
              toggleToolbar={handleToggleToolbar}
              toolbar={state.context.isToolbarOpen}
            />
          </HeaderContainer>
          {activeMealPlan ? (
            <MealPlanCard
              data-testid="my-mealplan-card"
              key={activeMealPlan.id}
              mealPlan={activeMealPlan}
              deleteMealPlan={handleDeleteMealplan}
              activateMealPlan={handleActivateMealplan}
              deactivateMealPlan={handleDeactivateMealplan}
              userNameInitials={state.context.userNameInitials}
            />
          ) : (
            <NoActiveMealPlan>
              <div>
                <span>
                  {mealPlans.length > 0
                    ? tt.placeHolderForActiveMealPlanWithPlans
                    : tt.placeHolderForActiveMealPlanNoPlans}
                </span>
              </div>
            </NoActiveMealPlan>
          )}
          {state.matches({ mealPlans: "loading" }) && <Loading />}
          {(state.matches({ mealPlans: "idle" }) || state.matches({ mealPlans: "generate" })) &&
            mealPlans.map((mealPlan: MealPlanForList, index: number) => (
              <MealPlanCard
                index={index}
                data-testid="my-mealplan-card"
                key={mealPlan.id}
                mealPlan={mealPlan}
                deleteMealPlan={handleDeleteMealplan}
                activateMealPlan={handleActivateMealplan}
                deactivateMealPlan={handleDeactivateMealplan}
                userNameInitials={state.context.userNameInitials}
              />
            ))}

          <div className="w-full flex justify-between text-base py-1.5">
            <button
              className={`font-medium ${queryPage <= 1 ? "opacity-25 cursor-default" : "opacity-100"}`}
              disabled={queryPage <= 1}
              onClick={handleNextQueryPage(Math.max(queryPage - 1, 1))}
            >
              {tt.paginationNewerButtonText}
            </button>
            <button
              className={`font-medium ${mealPlans.length < 4 ? "opacity-25 cursor-default" : "opacity-100"}`}
              disabled={mealPlans.length < 4}
              onClick={handleNextQueryPage(queryPage + 1)}
            >
              {tt.paginationPreviousText}
            </button>
          </div>
          <ButtonContainer data-testid="button-container">
            <div>
              {mealPlanPreferences ? (
                <IconTextButton
                  variant="contained"
                  color="green"
                  onClick={() => {
                    handleGenerateMealplan(), scrollToTop();
                  }}
                  disabled={state.matches("mealPlans.generate")}
                  icon={<FaPlus />}
                  dataTestid="mealplanner-add-personalized-week-button"
                >
                  {state.matches("mealPlans.generate") ? <LoadingIcon /> : tt.buttonAddPersonalizedWeek}
                </IconTextButton>
              ) : (
                <IconTextButton
                  variant="contained"
                  color="green"
                  onClick={handleNavigateToGenderStep}
                  icon={<FaChevronRight />}
                  dataTestid="mealplanner-go-to-servey-button"
                >
                  {tt.buttonGoToSurvey}
                </IconTextButton>
              )}

              <IconTextButton
                variant="contained"
                color="blue"
                onClick={handleNavigateToAllMealplansPage}
                icon={<DDIconWhite />}
                dataTestid="mealplanner-themed-meal-plans-button"
              >
                {tt.buttonThemedMealPlans}
              </IconTextButton>
              <IconTextButton
                variant="contained"
                color="pink"
                onClick={handleCreateMealplan}
                icon={<FaPen />}
                dataTestid="mealplanner-create-new-mealplan-button"
              >
                {tt.buttonCreateNewMealPlan}
              </IconTextButton>
            </div>
            {mealPlanPreferences ? (
              <IconTextButton
                variant="contained"
                color="grey"
                onClick={handleNavigateToSettingsPage}
                icon={<FaCog size="18" color="#727171" />}
                dataTestid="mealplanner-view-my-preferences-button"
              >
                {tt.buttonViewMyPreferences}
              </IconTextButton>
            ) : (
              <IconTextButton />
            )}
          </ButtonContainer>
        </Content>
      </Container>
    </>
  );
};

export default MealPlannerPage;

const DeleteDialog = styled.div`
  width: 300px;
  height: 350px;
  background-color: ${colors.GREY_LIGHTER};
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;

  > p {
    padding: 16px 0;
  }
  > button {
    border-top: 1px solid ${colors.GREY_LIGHT};
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 70px;
    color: #6187cd;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  > div {
    display: flex;
    align-items: center;
  }

  @media (max-width: ${LAPTOP_SM}) {
    flex-direction: column;
    align-items: center;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    button {
      margin: 0 auto 8px;
    }
  }
`;
const NoActiveMealPlan = styled.div`
  border-bottom: 1px solid ${colors.GREY_LIGHTER};

  > div {
    border-radius: 16px;
    background-color: ${colors.GREY_LIGHTEST};
    margin-bottom: 24px;
    margin-top: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
`;
