import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { default as colors } from "../../utils/colors.json";
import { LoadingIcon } from "../Icon/Icon";
type ButtonTypeVariant = "contained" | "outlined";
type ColorVariant = "blue" | "pink" | "grey" | "green" | "transparent";

interface ButtonColors {
  background: string;
  border: string;
  fontColor: string;
}

const COLOR_VARIANTS: { [key in ColorVariant]: ButtonColors } = {
  blue: {
    background: colors.BLUE,
    border: colors.BLUE,
    fontColor: colors.WHITE,
  },
  pink: {
    background: colors.PINK_LIGHT,
    border: colors.PINK_LIGHT,
    fontColor: colors.WHITE,
  },
  grey: {
    background: colors.GREY_LIGHTER,
    border: colors.GREY_LIGHTER,
    fontColor: colors.BLACK_LIGHTER,
  },
  green: { background: colors.GREEN, border: colors.GREEN, fontColor: colors.WHITE },
  transparent: { background: colors.WHITE, border: colors.WHITE, fontColor: colors.WHITE },
};

const BaseButton = styled.button<ButtonColors>`
  width: 220px;
  cursor: pointer;
  min-height: 40px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${({ background }) => background};
  color: ${({ fontColor }) => fontColor};
  border: 2px solid ${({ border }) => border};
  text-align: left;
  border-radius: 35px;
  padding-left: 16px;
  margin-right: 8px;
  &:hover {
    opacity: 0.5;
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    /* icon */
    > div {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }
`;

const OutlinedButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ border }) => border};
  &:hover {
    opacity: 0.5;
  }
`;

type ButtonProps = {
  variant?: ButtonTypeVariant;
  color?: ColorVariant;
  invertColors?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
  children?: ReactNode;
  dataTestid?: string;
};

const IconTextButton = ({
  variant = "contained",
  color = "transparent",
  loading = false,
  icon,
  onClick,
  children,
  dataTestid,
  ...rest
}: ButtonProps) => {
  const colors = COLOR_VARIANTS[color];
  const ButtonVariant = variant === "outlined" ? OutlinedButton : BaseButton;

  return (
    <ButtonVariant {...colors} data-testid={dataTestid} {...rest} onClick={onClick}>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div style={{ lineHeight: 1 }}>
          <div>{icon}</div>
          <span>{children}</span>
        </div>
      )}
    </ButtonVariant>
  );
};

export default IconTextButton;
