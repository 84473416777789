import React, { useContext } from "react";
import { t, Trans } from "@lingui/macro";
import { i18nContext } from "../I18nProvider/I18nProvider";

export function useTranslations() {
  const { translations } = useContext(i18nContext);

  return {
    ...translations,

    pageTitle: t({
      id: "MealPlannerPage.page-title",
      message: "My meal plans - Diet Doctor",
      comment: 'HTML title of the "my meal planner" page.',
    }),
    mainHeading: t({
      id: "MealPlannerPage.main-heading",
      message: "My meal plans",
      comment: 'Main heading of the "my meal planner" page.',
    }),

    buttonAddPersonalizedWeek: t({
      id: "MealPlannerPage.add-personalized-week",
      message: "Add personalized meal plan",
      comment:
        'Label for "add personalized meal plan" button, found at the "my meal planner" page, which will create a new week-long personalized meal plan for the user',
    }),
    buttonGoToSurvey: t({
      id: "MealPlannerPage.button.go-to-survey",
      message: "Go to survey",
      comment:
        'Label for "go to survey" button, found at the "my meal planner" page, which will take the user to the personalization survey',
    }),
    buttonThemedMealPlans: t({
      id: "MealPlannerPage.button.themed-meal-plans",
      message: "Themed meal plans",
      comment:
        'Label for "themed meal plans" button, found at the "my meal planner" page, which will take the user to the list of diet doctor own meal plans',
    }),
    buttonCreateNewMealPlan: t({
      id: "MealPlannerPage.button.create-new-meal-plan",
      message: "Create a new meal plan",
      comment:
        'Label for "create a new meal plan" button, found at the "my meal planner" page, which will create a new custom meal plan from scratch',
    }),
    buttonViewMyPreferences: t({
      id: "MealPlannerPage.button.view-my-preferences",
      message: "View my preferences",
      comment:
        'Label for "view my preferences" button, found at the "my meal planner" page, which will take the user to the page containing their meal plan preferences',
    }),
    buttonAddNewWeek: t({
      id: "MealPlannerPage.button.add-new-week",
      message: "Add new meal plan",
      comment: 'Label for "add new meal plan" button found at the "my meal planner" page',
    }),

    errorMessageCannotGetMealPlansComponent: (
      <Trans
        id="MealPlannerPage.error-message.cannot-get-meal-plans"
        comment="Error message displayed when there's an error fetching the user's meal plans."
      >
        Cannot get meal plans. <br /> Please try refreshing the page.
      </Trans>
    ),
    errorTitleCannotCreateMealPlan: t({
      id: "MealPlannerPage.error-title.cannot-create-meal-plan",
      message: "Cannot create meal plan!",
      comment: "Title of the error message displayed when meal plan creation fails.",
    }),
    errorMessageCreateMealPlan: t({
      id: "MealPlannerPage.error-message.cannot-create-meal-plan",
      message: "Make sure that you have a subscription before creating a meal plan",
      comment: "Error message displayed when meal plan creation fails.",
    }),
    errorTitleCannotCreatePersonalizedMealPlan: t({
      id: "MealPlannerPage.error-title.cannot-create-pmp",
      message: "We are sorry!",
      comment: "Title of the error message displayed when personalized meal plan creation fails.",
    }),
    errorMessageCannotCreatePersonalizedMealPlan: t({
      id: "MealPlannerPage.error-message.cannot-create-pmp",
      message:
        "Unfortunately, we were unable to create a meal plan for you. Please retake the survey and change one or more of your preferences. It should work next time!",
      comment: "Error message displayed when personalization meal plan creation fails.",
    }),
    placeHolderForActiveMealPlanNoPlans: t({
      id: "MealPlanner.placeholder.no-meal-plans",
      message:
        "Feeling hungry? Browse, create, or generate a week's worth of delicious meals. Get started below.",
      comment: "Placeholder for active meal plan when there are no meal plans",
    }),
    placeHolderForActiveMealPlanWithPlans: t({
      id: "MealPlanner.placeholder.has-meal-plans",
      message: "See your next meal. Start any of the meal plans below.",
      comment: "Placeholder for active meal plan when there are  meal plans",
    }),
    deleteDialogTitle: t({
      id: "MealPlanner.delete-dialog.title",
      message: "Removing meal plan.",
    }),
    deleteDialogButtonText: t({
      id: "MealPlanner.delete-dialog.button-text",
      message: "Undo",
    }),
    paginationNewerButtonText: t({
      id: "MealPlanner.pagination-newer.button-text",
      message: "Newer",
    }),
    paginationPreviousText: t({
      id: "MealPlanner.pagination-previous.button-text",
      message: "Previous",
    }),
  };
}
