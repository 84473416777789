import React from "react";
import styled from "@emotion/styled";
import { MOBILE_MD, MOBILE_LG, LAPTOP_SM } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import ResponsiveImage from "../Image/ResponsiveImage";

const ContainerWithHeaderImage = styled.div`
  position: relative;
  min-height: 100vh;
  margin: 0 0 36px;
  padding-top: 18%;

  display: flex;
  justify-content: center;

  > img {
    position: absolute;
    top: 0;
    border-radius: 0;
  }
`;
export const Container: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ContainerWithHeaderImage>
      <ResponsiveImage
        path={"/wp-content/uploads/2021/01/meal_plan_top_banner_2-1-1.jpg"}
        alt=""
        width={786}
        height={140}
        sizes={`(min-width: ${LAPTOP_SM}) 2560px, (min-width: ${MOBILE_LG}) 1440px, (min-width: ${MOBILE_MD}) 1024px, 768px`}
        dimensions={[
          { width: 768, height: 140 },
          { width: 1024, height: 220 },
          { width: 1440, height: 300 },
          { width: 2560, height: 614 },
        ]}
        defaultDimension={{ width: 768, height: 140 }}
        rounded
      />
      {children}
    </ContainerWithHeaderImage>
  );
};

export const Content = styled.div`
  padding-bottom: 8px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  padding: 0 20px 20px;
  h1 {
    font-size: 32px;

    color: ${colors.BLACK_LIGHTER};
    margin: 0;
    line-height: 1.2;

    @media (min-width: ${MOBILE_LG}) {
      font-size: 62px;
    }
  }

  @media (min-width: ${MOBILE_LG}) {
    padding: 0 50px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.GREY_LIGHTER};
  padding-bottom: 8px;
  h1 {
    font-size: 32px;
    min-height: 32px;

    color: ${colors.BLACK_LIGHTER};
    margin: 0;
    margin-bottom: 0.25rem;
    line-height: 1;
    @media (min-width: ${MOBILE_LG}) {
      font-size: 62px;
      min-height: 62px;
    }
  }
`;
export const Circle = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${colors.GREY_LIGHTER};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ErrorMessage = styled.h2`
  display: flex;
  justify-content: center;
  line-height: 1;
  text-align: center;
  font-size: 32px;
  z-index: 2;
  color: ${colors.BLACK_LIGHTER};
`;
