import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH";
import React, { useContext, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import CreateMealPlanIcon from "../../icons/MealPlanCardIcons/CreateMealPlanIcon";
import PersonalizedMealPlanIcon from "../../icons/MealPlanCardIcons/PersonalizedMealPlanIcon";
import ThemedMealPlanIcon from "../../icons/MealPlanCardIcons/ThemedMealPlanIcon";
import { OnboardingGenderPage } from "../../ecosystems/onboarding/pages";
import { UserFoodPreferencesPage } from "../../pages";
import { UpdateUser_updateUser } from "../../types/UpdateUser";
import { MOBILE_LG } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import { getUserInitials } from "../../utils/string";
import { LoadingIcon } from "../Icon/Icon";
import { useNavigate } from "../Link/Link";
import { LoginContext } from "../LoginProvider/LoginProvider";
import { Circle } from "./Container";
import { useTranslations } from "./translations";

interface ToolbarProps {
  mealPlanPreferences?: UpdateUser_updateUser;
  loading: boolean;
  generateNext: () => void;
  createMealPlan: () => void;
  viewThemedMealPlans: () => void;
  toggleToolbar: (toggle: boolean) => void;
  toolbar: boolean;
}
const Toolbar: React.FunctionComponent<ToolbarProps> = ({
  mealPlanPreferences,
  generateNext,
  loading,
  createMealPlan,
  viewThemedMealPlans,
  toggleToolbar,
  toolbar,
}) => {
  const navigate = useNavigate();
  const tt = useTranslations();
  const node = useRef<HTMLDivElement>(null);
  const { user } = useContext(LoginContext);
  const usersInitials = user && getUserInitials(user);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (node.current?.contains?.(e.target)) {
        //inside click
        return;
      }
      // outside click
      toggleToolbar(false);
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const toolbarItems = [
    mealPlanPreferences
      ? {
          name: loading ? <LoadingIcon /> : tt.buttonAddPersonalizedWeek,
          event: () => {
            generateNext();
          },
          icon: <PersonalizedMealPlanIcon name={usersInitials} />,
        }
      : {
          name: tt.buttonGoToSurvey,
          event: () =>
            navigate({
              to: OnboardingGenderPage,
            }),
          icon: <PersonalizedMealPlanIcon name={usersInitials} />,
        },
    {
      name: tt.buttonThemedMealPlans,
      event: viewThemedMealPlans,
      icon: <ThemedMealPlanIcon />,
    },
    {
      name: tt.buttonCreateNewMealPlan,
      event: () => createMealPlan(),
      icon: <CreateMealPlanIcon />,
    },

    ...(mealPlanPreferences
      ? [
          {
            name: tt.buttonViewMyPreferences,
            event: () => navigate({ to: UserFoodPreferencesPage }),
            icon: (
              <Circle>
                <FaCog size="24" color="#727171" />
              </Circle>
            ),
          },
        ]
      : []),
  ];

  return (
    <StyledToolbar ref={node}>
      {/* desktop menu */}
      <div>
        <AddNewWeekButton
          data-testid="mealplanner-add-new-week-button"
          open={toolbar}
          onClick={() => toggleToolbar(!toolbar)}
        >
          <span>{tt.buttonAddNewWeek}</span>

          {toolbar ? (
            <FaChevronUp style={{ pointerEvents: "none" }} />
          ) : (
            <FaChevronDown style={{ pointerEvents: "none" }} />
          )}
        </AddNewWeekButton>
        {mealPlanPreferences && (
          <Circle onClick={() => navigate({ to: UserFoodPreferencesPage })} style={{ marginLeft: "16px" }}>
            <FaCog size="24" color="#727171" />
          </Circle>
        )}
      </div>
      {/* mobile menu */}
      <div>
        <Circle onClick={() => toggleToolbar(!toolbar)}>
          <FaEllipsisH />
        </Circle>
      </div>
      {toolbar && (
        <Menu>
          {toolbarItems.map((item, index) => (
            <div key={index}>
              <div>{item.icon}</div>
              <p onClick={item.event}>{item.name}</p>
            </div>
          ))}
        </Menu>
      )}
    </StyledToolbar>
  );
};

export default Toolbar;

const StyledToolbar = styled.div`
  > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: calc((${MOBILE_LG}) - 1px)) {
    > div:nth-of-type(1) {
      display: none;
    }
  }
  @media (min-width: ${MOBILE_LG}) {
    > div:nth-of-type(2) {
      display: none;
    }
  }
`;

const AddNewWeekButton = styled.button`
  background-color: ${(props: { open: boolean }) => (props.open ? `${colors.GREY}` : `${colors.GREY_LIGHTER}`)};
  border-radius: 35px;
  border: ${(props: { open: boolean }) =>
    props.open ? `2px solid ${colors.GREY}` : `2px solid ${colors.GREY_LIGHTER}`};
  padding: 8px 24px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
  color: ${(props: { open: boolean }) => (props.open ? "white" : `${colors.BLACK_LIGHTER}`)};
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > span {
    margin-right: 16px;
  }
  :hover {
    background-color: ${colors.GREY};
    border: 2px solid ${colors.GREY};
    color: white;
  }
`;
const Menu = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  position: absolute;
  background-color: white;
  right: 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  z-index: 3;
  padding: 8px 32px 8px 16px;
  margin-right: 48px;

  @media (min-width: ${MOBILE_LG}) {
    margin-right: 105px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      margin: 8px 16px 8px 0;
    }
    > p {
      cursor: pointer;
      width: 100%;
    }
  }

  /*If user has settings, then they are shown with the cog icon on desktop, not in menu */
  @media (min-width: ${MOBILE_LG}) {
    > div:nth-of-type(4) {
      display: none;
    }
  }
`;
