import React from "react";

export default function DDIconWhite() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 24V21H5.98952C10.9514 21 14.9738 16.9706 14.9738 12C14.9738 7.02944 10.9514 3 5.98952 3H0V0H5.98952C12.6054 0 17.9686 5.37258 17.9686 12C17.9686 18.6274 12.6054 24 5.98952 24H0ZM5.98952 6C9.29744 6 11.979 8.68629 11.979 12C11.979 15.3137 9.29744 18 5.98952 18H0V6H5.98952ZM2.99476 15H5.98952C7.64348 15 8.98428 13.6569 8.98428 12C8.98428 10.3431 7.64348 9 5.98952 9H2.99476V15Z"
        fill="white"
      />
    </svg>
  );
}
