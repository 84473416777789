import { sha256 } from "js-sha256";
import { assign, createMachine, DoneInvokeEvent, send } from "xstate";
import { cancel } from "xstate/lib/actions";
import ActivateMealPlan from "../../graphql/ActivateMealPlan.graphql";
import DeactivateMealPlan from "../../graphql/DeactivateMealPlan.graphql";
import GenerateNextMealplanMutation from "../../graphql/GenerateNextMealplan.graphql";
import GetActiveFullMealPlan from "../../graphql/GetActiveFullMealPlan.graphql";
import GetUserPreferences from "../../graphql/GetUserPreferences.graphql";
import CreateMealplan from "../../graphql/useCreateMealplan/CreateMealplan.graphql";
import DeleteMealplan from "../../graphql/useDeleteMealplan/DeleteMealplan.graphql";
import GetEssentialDataForUserMealPlans from "../../graphql/useUserMealPlans/GetEssentialDataForUserMealPlans.graphql";
import { MealPlanPage } from "../../ecosystems/mealplans/pages";
import { OnboardingGenderPage } from "../../ecosystems/onboarding/pages";
import { AllMealPlansPage, HomePage, UserFoodPreferencesPage } from "../../pages";
import { client } from "../../utils/apollo-client";
import { sendEvent } from "../../utils/gtm";
import {
  ActivateMealPlanEvent,
  Context,
  CreateMealplanType,
  DataPayload,
  DeleteMealPlanEvent,
  MachineOptions,
  MealPlanListEvent,
  ToggleToolbarEvent,
  UpdateQueryPageEvent,
  UserStateChangeEvent,
} from "./types";

const settings = 0;
const mealPlans = 1;
const activeMealPlans = 2;

export const machine = (options: MachineOptions) =>
  createMachine<Context, MealPlanListEvent>(
    {
      id: "list",
      initial: "init",
      context: {
        queryPage: 1,
        mealPlans: [],
        isPremium: false,
        isToolbarOpen: false,
        activeMealPlan: undefined,
        userNameInitials: "",
        mealPlanIdToBeDeleted: "",
      },
      states: {
        init: {
          on: {
            USER_STATE_CHANGE: [
              { target: "loading", actions: ["updateUserState"], cond: "isPremium" },
              { actions: ["navigateToHome"] },
            ],
          },
        },
        loading: {
          invoke: {
            src: "loadData",
            onDone: {
              target: "mealPlans",
              actions: [
                "updateMealPlanPreferences",
                "updateMealPlans",
                "updateActiveMealPlan",
                "viewMealPlanListAnalytics",
              ],
            },
            onError: { target: ["error"] },
          },
        },
        error: {},
        mealPlans: {
          initial: "idle",
          states: {
            idle: {
              on: {
                GENERATE_MEALPLAN: { target: "generate" },
                DELETE_MEALPLAN: {
                  target: ".showDeleteDialog",
                },
                CREATE_MEALPLAN: {
                  target: "createFromScratch",
                },
                ACTIVATE_MEALPLAN: { target: "activate" },
                DEACTIVATE_MEALPLAN: { target: "deactivate" },
                TOGGLE_TOOLBAR: { actions: ["toggleToolbar"] },
                NAVIGATE_TO_SETTINGS_PAGE: { actions: ["navigateToSettingsPage"] },
                NAVIGATE_TO_ALL_MEAL_PLANS_PAGE: { actions: ["navigateToAllMealPlansPage"] },
                NAVIGATE_TO_GENDER_STEP: { actions: ["navigateToGenderStep"] },
                UPDATE_QUERY_PAGE: {
                  actions: ["setQueryPage"],
                  target: "loading",
                },
              },
              initial: "idle",
              states: {
                idle: {},
                showDeleteDialog: {
                  entry: ["startTimer", "saveMealPlanToBeDeletedToContext"],
                  on: {
                    DELETE_FROM_DB: {
                      target: "#list.mealPlans.delete",
                    },
                    UNDO_DELETE: {
                      actions: [cancel("timer")],
                      target: "idle",
                    },
                  },
                },
              },
            },
            createFromScratch: {
              invoke: {
                src: "createMealPlan",
                onDone: { actions: ["redirectToMealPlanPage"] },
                onError: "error",
              },
            },
            generate: {
              invoke: {
                src: "generateMealPlan",
                onDone: { target: "loading", actions: ["successfulMealPlanGenerationAnalytics"] },
                onError: {
                  target: "generateMealPlanError",
                  actions: ["failedMealPlanGenerationAnalytics"],
                },
              },
            },
            delete: {
              invoke: {
                src: "deleteMealPlan",
                onDone: { target: "loading" },
                onError: "error",
              },
            },
            activate: {
              invoke: {
                src: "activateMealPlan",
                onDone: { target: "loading", actions: ["analyticsActivateMealPlan"] },
                onError: { target: "error" },
              },
            },
            deactivate: {
              invoke: {
                src: "deactivateMealPlan",
                onDone: { target: "loading" },
                onError: { target: "error" },
              },
            },
            loading: {
              invoke: {
                src: "loadData",
                onDone: {
                  target: "idle",
                  actions: ["updateMealPlanPreferences", "updateMealPlans", "updateActiveMealPlan"],
                },
              },
            },

            error: {},
            generateMealPlanError: {
              on: { NAVIGATE_TO_GENDER_STEP: { actions: ["navigateToGenderStep"] } },
            },
          },
        },
      },
    },
    {
      actions: {
        setQueryPage: assign({
          queryPage: (_, e) => (e as UpdateQueryPageEvent).queryPage,
        }),
        updateUserState: assign({
          isPremium: (_, event) => (event as UserStateChangeEvent).premium,
          userNameInitials: (_, event) => (event as UserStateChangeEvent).userNameInitials,
          userId: (_, event) => (event as UserStateChangeEvent).userId,
        }),
        updateMealPlans: assign({
          mealPlans: (_, event) =>
            (event as DoneInvokeEvent<DataPayload>).data[mealPlans].userMealplans.filter(
              (item) => item.isActivePlan === false
            ),
        }),
        updateActiveMealPlan: assign({
          activeMealPlan: (_, event) =>
            (event as DoneInvokeEvent<DataPayload>).data[activeMealPlans].activeMealplan,
        }),
        updateMealPlanPreferences: assign({
          mealPlanPreferences: (_, event) => (event as DoneInvokeEvent<DataPayload>).data[settings].getUser,
        }),

        toggleToolbar: assign({
          isToolbarOpen: (_, event) => (event as ToggleToolbarEvent).toggle,
        }),
        startTimer: send("DELETE_FROM_DB", {
          delay: 2000,
          id: "timer",
        }),
        saveMealPlanToBeDeletedToContext: assign({
          mealPlanIdToBeDeleted: (_, event) => (event as DeleteMealPlanEvent).id,
        }),
        navigateToHome: () => options.navigate({ to: HomePage }),
        navigateToSettingsPage: () => options.navigate({ to: UserFoodPreferencesPage }),
        navigateToAllMealPlansPage: () => options.navigate({ to: AllMealPlansPage }),
        navigateToGenderStep: () =>
          options.navigate({
            to: OnboardingGenderPage,
          }),

        redirectToMealPlanPage: (_, event) =>
          options.navigate({
            to: MealPlanPage,
            params: {
              slug: (event as DoneInvokeEvent<CreateMealplanType>).data.data.createMealplan.slug,
            },
            query: { edit: true },
          }),

        successfulMealPlanGenerationAnalytics: (ctx) =>
          sendEvent({
            eventName: "meal_planner",
            action: "successful_generate_meal_plan",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
              preferences: ctx.mealPlanPreferences!,
            },
          }),
        failedMealPlanGenerationAnalytics: (ctx) =>
          sendEvent({
            eventName: "meal_planner",
            action: "failed_generate_meal_plan",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
              preferences: ctx.mealPlanPreferences!,
            },
          }),
        viewMealPlanListAnalytics: (ctx) =>
          sendEvent({
            eventName: "meal_planner",
            action: "view_meal_plan_list",
            payload: {
              userId: ctx.userId ? sha256(ctx.userId) : "",
              isPremium: ctx.isPremium,
            },
          }),
      },
      services: {
        loadData: (ctx) =>
          Promise.all([
            client.query({ query: GetUserPreferences, fetchPolicy: "network-only" }).then((res) => res.data),
            client
              .query({
                query: GetEssentialDataForUserMealPlans,
                variables: { page: ctx.queryPage, order: "DESC" },
                fetchPolicy: "network-only",
              })
              .then((res) => res.data),
            client.query({ query: GetActiveFullMealPlan, fetchPolicy: "network-only" }).then((res) => res.data),
          ]),
        generateMealPlan: () => client.mutate({ mutation: GenerateNextMealplanMutation }),

        deleteMealPlan: (ctx) =>
          client.mutate({
            mutation: DeleteMealplan,
            variables: { id: ctx.mealPlanIdToBeDeleted },
          }),
        activateMealPlan: (_, event) =>
          client.mutate({
            mutation: ActivateMealPlan,
            variables: { id: (event as ActivateMealPlanEvent).id },
          }),
        deactivateMealPlan: () =>
          client.mutate({
            mutation: DeactivateMealPlan,
          }),

        createMealPlan: () =>
          //sending mealplan:{} in order to create an empty meal plan
          client.mutate({ mutation: CreateMealplan, variables: { mealplan: {} } }),
      },
      guards: {
        isPremium: (_, event) => (event as UserStateChangeEvent).premium === true,
      },
    }
  );
